@include b(table) {
  background-color: transparent;

  tr {
    background-color: $black;

    td {
      &.el-table__cell {
        border-bottom: 1px solid #534f4f !important;
      }
    }

    th .cell,
    td .cell {
      word-break: break-word;
      text-align: center;
    }

    td,
    th.is-leaf {
      border-bottom: 0;
    }
  }

  thead {
    &.is-group {
      th.el-table__cell {
        background: $--table-header-background-color;
      }
    }

    tr:not(:first-child) {
      th.el-table__cell {
        &:first-child {
          border-radius: 0;
        }

        &:nth-last-child(2) {
          border-radius: 0;
        }
      }
    }
  }

  th {
    &.el-table__cell {
      // &:first-child {
      //   @include rtl-sass-prop-dual(border-top-left-radius, $border-radius, border-top-right-radius, $border-radius);
      //   @include rtl-sass-prop-dual(border-bottom-left-radius,
      //     $border-radius,
      //     border-bottom-right-radius,
      //     $border-radius);
      // }

      >.cell {
        &.highlight {
          color: $--table-header-font-color;
        }
      }

      // &:nth-last-child(2) {
      //   @include rtl-sass-prop-dual(border-top-right-radius, $border-radius, border-top-left-radius, $border-radius);
      //   @include rtl-sass-prop-dual(border-bottom-right-radius,
      //     $border-radius,
      //     border-bottom-left-radius,
      //     $border-radius);
      // }
    }
  }

  .sort-caret {
    &.ascending {
      border-bottom-color: $yellow1;
    }

    &.descending {
      border-top-color: $yellow1;
    }
  }

  @include e(column-filter-trigger) {
    & i {
      color: $yellow1;
    }
  }
}

@include b(table-filter) {
  color: $graphite;

  .el-checkbox {
    color: $graphite;
  }
}