@include b(radio) {
  @include rtl-sass-prop(margin-right, margin-left, map-get($spacers, 4));
  @include rtl-sass-prop(margin-left, margin-right, map-get($spacers, 0));
  @include e(input) {
    @include when(disabled) {
      & + span.el-radio__label {
        color: $font-color-disabled;
      }
    }
  }
  @include e(inner) {
    &::after {
      width: 0.375rem;
      height: 0.375rem;
    }
  }
  @include e(label) {
    @include rtl-sass-prop(padding-left, padding-right, map-get($spacers, 2));
  }
}
