@include b(notification) {
  background-color: $--notification-border-color;

  @include e(closeBtn) {
    color: $white;
    font-size: 1rem;
    padding: 3px;
    background-color: rgba($black, 0.4);
    border-radius: $border-radius-circle;
    @include rtl-sass-prop(right, left, map-get($spacers, 3));
    @include rtl-sass-prop(left, right, auto);

    &:hover {
      color: $white;
      background-color: rgba($black, 0.6);
    }
  }
}
