.el-upload {
  width: 100%;
}

.upload_demo {
  height: $--input-height;
  border-radius: $--input-border-radius;
  border: $--input-border;
  position: relative;
}

.el-upload img {
  position: absolute;
  right: 0;
  top: 0;
  background: -moz-linear-gradient(0deg, #b1b1b1 0%, #e7e7e7 100%), -moz-linear-gradient(#adadad, #adadad);
  background: -webkit-linear-gradient(0deg, #b1b1b1 0%, #e7e7e7 100%), -webkit-linear-gradient(#adadad, #adadad);
  background: -o-linear-gradient(0deg, #b1b1b1 0%, #e7e7e7 100%), -o-linear-gradient(#adadad, #adadad);
  background: linear-gradient(0deg, #b1b1b1 0%, #e7e7e7 100%), linear-gradient(#adadad, #adadad);
  border-radius: $--input-border-radius;
}

.upload_demo .button_upload {
  font-size: $--font-size-base;
  height: $--input-height;
  color: $dark-green;
  padding: 0 10px;
  position: absolute;
  right: -1px;
  top: 0;
  background: -moz-linear-gradient(0deg, #b1b1b1 0%, #e7e7e7 100%), -moz-linear-gradient(#adadad, #adadad);
  background: -webkit-linear-gradient(0deg, #b1b1b1 0%, #e7e7e7 100%), -webkit-linear-gradient(#adadad, #adadad);
  background: -o-linear-gradient(0deg, #b1b1b1 0%, #e7e7e7 100%), -o-linear-gradient(#adadad, #adadad);
  background: linear-gradient(0deg, #b1b1b1 0%, #e7e7e7 100%), linear-gradient(#adadad, #adadad);
  border-radius: $--input-border-radius;
  text-align: center;
  display: flex;
  align-items: center;
}

.el-icon-document {
  @include rtl-sass-prop(margin-right, margin-left, 7px);
}

.el-upload__tip {
  font-size: 12px;
  line-height: 1.3;
  color: $font-color-base;
  text-align: center;
  word-break: break-word;
}

.el-form-item {
  &.is-error {
    .upload_demo {
      border-color: $red;
    }
  }
}
