.el-input {
  .el-input__inner {
    border-color: $border-color;
    color: $dark-green;

    @include placeholder {
      color: $stone-gray;
    }

    &:hover {
      border-color: $--input-hover-border;
    }

    &:focus {
      outline: none;
      border-color: $--input-focus-border;
    }
  }
  @include when(disabled) {
    .el-input__inner {
      @include card-disabled-input();
      color: $graphite;
    }
  }
}
.el-select {
  .el-input {
    &.is-disabled {
      .el-input__inner:hover {
        border-color: $neutral-gray;
      }
    }
  }
}

.el-radio {
  color: $graphite;
}

.el-radio__input {
  &.is-checked + .el-radio__label {
    color: $graphite;
  }

  &.is-disabled {
    &.is-checked {
      .el-radio__inner {
        @include card-disabled-input();

        &:after {
          background-color: $graphite;
        }
      }

      + .el-radio__label {
        color: $stone-gray;
      }
    }
    .el-radio__inner {
      @include card-disabled-input();
    }

    & + span.el-radio__label {
      color: $stone-gray;
    }
  }
}

.el-checkbox {
  color: $graphite;
}

.el-checkbox__input {
  &.is-checked + .el-checkbox__label {
    color: $graphite;
  }

  &.is-disabled {
    &.is-checked {
      .el-checkbox__inner {
        @include card-disabled-input();

        &:after {
          border-color: $graphite;
        }
      }

      + .el-checkbox__label {
        color: $stone-gray;
      }
    }
    .el-checkbox__inner {
      @include card-disabled-input();
    }

    & + span.el-checkbox__label {
      color: $stone-gray;
    }
  }
}

.el-date-editor {
  .el-range-input {
    color: $dark-green;

    @include placeholder {
      color: $stone-gray;
    }
  }

  .el-range-separator {
    color: $graphite;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    background-color: $neutral-gray;
  }
}

.el-form-item__label {
  color: $graphite;
}

.el-form-item {
  &.is-error {
    .el-input__inner,
    .el-input__inner:focus,
    .el-textarea__inner,
    .el-textarea__inner:focus {
      border-color: $--color-danger;
    }
  }
}

.el-upload__tip {
  color: $white;
}
