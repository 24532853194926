.el-scrollbar__wrap {
  scrollbar-width: none;
  overflow: scroll;
  @include rtl-sass-prop-dual(margin-right, 0, margin-right, 0 !important);
  @include rtl-sass-prop-dual(margin-left, 0, margin-left, -6px !important);
}

/* 修改手機點擊兩次 */
.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1;
  }
}
