@include b(checkbox) {
  @include rtl-sass-prop(margin-right, margin-left, map-get($spacers, 4));
  @include rtl-sass-prop(margin-left, margin-right, map-get($spacers, 0));
  @include e(inner) {
    &:after {
      height: 7px;
      left: 6px;
      top: 3px;
      width: 3px;
      border-width: 2px;
    }
  }
  @include e(label) {
    @include rtl-sass-prop(padding-left, padding-right, map-get($spacers, 2));
  }
}

.el-checkbox__label {
  @include rtl-sass-prop(padding-right, padding-left, 0);
  @include rtl-sass-prop(padding-left, padding-right, 10px);
}
