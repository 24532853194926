@include b(button) {
  & + & {
    @include rtl-sass-prop(margin-left, margin-right, map-get($spacers, 2));
    @include rtl-sass-prop(margin-right, margin-left, map-get($spacers, 0));
  }

  @include btn-variant(
    $--button-default-font-color,
    $--button-default-background-color,
    $--button-default-border-color
  );

  @include when(plain) {
    color: $--button-default-background-color;
    border-color: $--button-default-background-color;
    background-color: transparent;

    &:hover,
    &:focus {
      color: $--button-default-font-color;
      border-color: $--button-default-background-color;
      background-color: $--button-default-background-color;
    }

    &:active {
      background: mix($--button-default-font-color, $--button-default-background-color, $--button-active-shade-percent);
      border-color: mix(
        $--button-default-font-color,
        $--button-default-background-color,
        $--button-active-shade-percent
      );
      color: $--button-default-font-color;
    }
  }

  @include m(primary) {
    @include btn-variant(
      $--button-primary-font-color,
      $--button-primary-background-color,
      $--button-primary-border-color
    );
  }
  @include m(success) {
    @include btn-variant(
      $--button-success-font-color,
      $--button-success-background-color,
      $--button-success-border-color
    );
  }
  @include m(warning) {
    @include btn-variant(
      $--button-warning-font-color,
      $--button-warning-background-color,
      $--button-warning-border-color
    );
  }
  @include m(danger) {
    @include btn-variant($--button-danger-font-color, $--button-danger-background-color, $--button-danger-border-color);
  }
  @include m(info) {
    @include btn-variant($--button-info-font-color, $--button-info-background-color, $--button-info-border-color);
  }
}
