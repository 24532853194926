.el-popover {
  color: $white;
  word-break: normal;
  @include rtl-sass-value(text-align, left, right);
}
.popper__arrow {
  &::after {
    left: 0;
  }
}

.el-popover{
  &.teal_popper {
    background:$teal;
    border: 1px solid $teal;
  }
  .popper__arrow,
  .popper__arrow::after{
    border-bottom-color: $teal !important;
  }
}