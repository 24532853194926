@include b(select) {
  width: 100%;

  .el-input {
    .el-select__caret {
      color: $primary-alt;
      font-size: $h5-font-size;

      &:before {
        content: '\e78f';
      }
    }
  }
}

.el-select-group__title {
  color: $yellow1;
}