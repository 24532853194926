/* Color
-------------------------- */
$--color-primary: $primary;
$--color-white: $white;
$--color-black: $black;
$--color-success: $green;
$--color-warning: $light-coral;
$--color-danger: $dark-magenta;
$--color-info: $sky-blue;

/* Button
-------------------------- */
$--button-font-size: $font-size-base;
$--button-font-weight: $font-weight-normal;
$--button-border-radius: $border-radius-round;
$--button-padding-vertical: 0.75rem;
$--button-padding-horizontal: 1.5rem;
$--button-default-font-color: $black;
$--button-default-background-color: $white;
$--button-default-border-color: $white;

/* Table
-------------------------- */
$--table-border-color: transparent;
$--table-border: 1px solid $--table-border-color;
$--table-font-color: $font-color-base;
$--table-header-font-color: $font-color-base;
$--table-row-hover-background-color: $black2;
$--table-header-background-color: $black2;

/* Pagination
-------------------------- */
$--pagination-background-color: transparent;
$--pagination-font-color: $font-color-muted;
$--pagination-border-radius: $border-radius;
$--pagination-button-color: $font-color-muted;
$--pagination-button-disabled-color: $font-color-disabled;
$--pagination-button-disabled-background-color: transparent;
$--pagination-hover-color: $primary-alt;

/* Card
--------------------------*/
$--card-border-radius: $border-radius;
$--card-padding: 25px;

/* Dialog
-------------------------- */
$--dialog-background-color: $white;
$--dialog-title-font-size: $h4-font-size;
$--dialog-content-font-size: $font-size-base;
$--dialog-padding-primary: $--card-padding;

/* Input
-------------------------- */
$--input-font-size: $font-size-base;
$--input-font-color: $font-color-base;
$--input-width: 140px;
$--input-height: 40px;
$--input-border-color: $black3;
$--input-border: $border-width solid $--input-border-color;
$--input-border-radius: 0;
$--input-border-color-hover: $white;
$--input-background-color: transparent;
$--input-placeholder-color: rgba($--input-font-color, 0.5);
$--input-hover-border: $white;
$--input-focus-border: $white;
$--input-clear-hover-color: $white;
$--input-disabled-fill: $theme-hue;
$--input-disabled-border: $--input-disabled-fill;
$--input-disabled-color: $font-color-disabled;
$--input-disabled-placeholder-color: rgba($--input-placeholder-color, 0.5);

/* Select
-------------------------- */
$--select-border-color-hover: $--input-hover-border;
$--select-disabled-border: $--input-disabled-border;
$--select-font-size: $font-size-base;
$--select-close-hover-color: $primary-alt;
$--select-input-color: $--input-font-color;
$--select-input-focus-border-color: $--input-focus-border;
$--select-input-font-size: $--input-font-size;
$--select-option-color: $graphite;
$--select-option-selected-font-color: $black;
$--select-option-selected-hover: $light-gray;
$--select-option-hover-background: $light-gray;
$--select-dropdown-background: $white;
$--select-multiple-input-color: $--select-input-color;
$--select-option-disabled-color: $stone-gray;
$--select-option-disabled-background: $--select-dropdown-background;
$--select-option-height: 2.2rem;

/* Radio
-------------------------- */
$--radio-font-size: $font-size-base;
$--radio-font-weight: $font-weight-normal;
$--radio-font-color: $font-color-base;
$--radio-input-height: 1.25rem;
$--radio-input-width: 1.25rem;
$--radio-input-border-radius: $border-radius-circle;
$--radio-input-background-color: transparent;
$--radio-input-border-color: $primary-alt;
$--radio-icon-color: $white;
$--radio-checked-input-border-color: $primary-alt;
$--radio-checked-icon-color: $primary-alt;
$--radio-input-border-color-hover: $primary-alt;
$--radio-checked-font-color: $--radio-font-color;
$--radio-disabled-input-border-color: $--input-disabled-border;
$--radio-disabled-input-fill: $--input-disabled-fill;
$--radio-disabled-icon-color: $dark-green;
$--radio-disabled-checked-input-border-color: $--radio-disabled-input-border-color;
$--radio-disabled-checked-input-fill: $--radio-disabled-input-fill;
$--radio-disabled-checked-icon-color: $--radio-disabled-icon-color;
$--radio-checked-input-background-color: $primary-alt;

/* Checkbox
-------------------------- */
$--checkbox-font-size: $font-size-base;
$--checkbox-font-weight: $font-weight-normal;
$--checkbox-font-color: $font-color-base;
$--checkbox-input-height: 1.25rem;
$--checkbox-input-width: 1.25rem;
$--checkbox-border-radius: $border-radius-xs;
$--checkbox-background-color: transparent;
$--checkbox-input-border: $border-width solid $--input-border-color;
$--checkbox-checked-font-color: $primary-alt;
$--checkbox-checked-input-border-color: $primary-alt;
$--checkbox-checked-background-color: $primary-alt;
$--checkbox-checked-icon-color: $white;
$--checkbox-input-border-color-hover: $primary-alt;
$--checkbox-disabled-border-color: $--input-disabled-border;
$--checkbox-disabled-input-fill: $--input-disabled-fill;
$--checkbox-disabled-icon-color: $dark-green;
$--checkbox-disabled-checked-input-fill: $--checkbox-disabled-input-fill;
$--checkbox-disabled-checked-input-border-color: $--checkbox-disabled-border-color;
$--checkbox-disabled-checked-icon-color: $--checkbox-disabled-icon-color;

/* Tooltip
-------------------------- */
$--tooltip-fill: rgba($black, 0.95);
$--tooltip-color: $white;
$--tooltip-font-size: 80%;
$--tooltip-border-color: $--tooltip-fill;
$--tooltip-arrow-size: 6px;
$--tooltip-padding: map-get($spacers, 2);

/* DatePicker
--------------------------*/
$--datepicker-off-font-color: rgba($graphite, 0.4);
$--datepicker-header-font-color: $graphite;
$--datepicker-icon-color: $primary-alt;
$--datepicker-border-color: $border-color;
$--datepicker-inrange-background-color: $light-gray;
$--datepicker-inrange-hover-background-color: $--datepicker-inrange-background-color;
$--datepicker-active-color: $primary-alt;
$--datepicker-hover-font-color: $primary-alt;
$--datepicker-cell-hover-color: $primary-alt;

/* Notification
-------------------------- */
$--notification-radius: $border-radius-lg;
$--notification-border-color: $primary-alt;
$--notification-content-font-size: $font-size-base;
$--notification-content-color: $font-color-base;
$--notification-title-font-size: $h5-font-size;
$--notification-title-color: $--notification-content-color;
$--notification-success-icon-color: $white;
$--notification-info-icon-color: $white;
$--notification-warning-icon-color: $white;
$--notification-danger-icon-color: $white;

/* Popover
-------------------------- */
$--popover-background-color: $white;
$--popover-font-size: $font-size-base;
$--popover-border-color: $white;
$--popover-title-font-size: $h5-font-size;
$--popover-title-font-color: $white;

/* Cascader
-------------------------- */
$--cascader-menu-font-color: $graphite;
$--cascader-menu-selected-font-color: $black;
$--cascader-menu-fill: $white;
$--cascader-menu-font-size: $font-size-base;
$--cascader-menu-radius: $border-radius;
$--cascader-menu-border: 1px solid $border-color;
$--cascader-node-background-hover: $light-gray;

/* Collapse
-------------------------- */
$--collapse-border-color: $white;