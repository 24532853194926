body,
div,
dl,
dt,
dd,
ul,
ol,
li,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('~@/assets/fonts/ProximaNova/ProximaNova-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('~@/assets/fonts/ProximaNova/ProximaNova-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~@/assets/fonts/ProximaNova/ProximaNova-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('~@/assets/fonts/ProximaNova/ProximaNova-Light.otf') format('opentype');
}

#convrs-chat-channels {
  display: none;
}

body {
  font-size: $font-size-base;
  font-family: $font-family-base;
  color: $font-color-base;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  margin-top: 0;
}

h1,
.h1 {
  font-size: $h1-font-size;
}

h2,
.h2 {
  font-size: $h2-font-size;
}

h3,
.h3 {
  font-size: $h3-font-size;
}

h4,
.h4 {
  font-size: $h4-font-size;
}

h5,
.h5 {
  font-size: $h5-font-size;
}

h6,
.h6 {
  font-size: $h6-font-size;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

a {
  color: #555;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none !important;
}

img {
  border: none;
  max-width: 100%;
  vertical-align: middle;
}

ol,
ul,
li {
  list-style: none;
}

input,
textarea,
select,
button {
  font: 14px 'CenturyGothic', 'Arial', 'Microsoft YaHei', '黑体', '宋体', sans-serif;
}

table {
  border-collapse: collapse;
}

.clearfix:after {
  content: '.';
  /*加一段内容*/
  display: block;
  /*让生成的元素以块级元素显示，占满剩余空间*/
  height: 0;
  /*避免生成的内容破坏原有布局高度*/
  clear: both;
  /*清除浮动*/
  visibility: hidden;
  /*让生成的内容不可见*/
}

.clearfix {
  zoom: 1;
  /*为IE6，7的兼容性设置*/
}

/*公共类*/

.fl {
  float: left;
}

.fr {
  float: right;
}

.al {
  text-align: left;
}

.ac {
  text-align: center;
}

.ar {
  text-align: right;
}

.hide {
  display: none;
}

.red {
  color: $dark-magenta;
}

.green {
  color: $green;
}

input {
  border: none;
  outline: none;
}

select {
  border: none;
  outline: none;
}

p {
  line-height: 1.3;
}

@media (min-width: 991px) {

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    transition: background-color 0.3s;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.3);
    transition: background-color 0.3s;
  }
}