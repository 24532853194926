@include b(dialog) {
  border-radius: $border-radius-lg;

  @include screen-mobile() {
    max-width: 500px;
    width: 90% !important;
  }

  @include e(title) {
    color: $graphite;
    font-weight: $font-weight-bold;
  }

  @include e(body) {
    color: $graphite;
    word-break: break-word;
  }

  @include e(footer) {
    padding: $--dialog-padding-primary;
    color: $graphite;
  }

  @include e(headerbtn) {
    @include rtl-sass-prop(left, right, unset);
    @include rtl-sass-prop(right, left, 25px);

    .el-dialog__close {
      color: $white;
    }

    &:focus,
    &:hover {
      .el-dialog__close {
        color: $dark-green;
      }
    }
  }
  .el-pagination {
    color: $graphite;

    button:disabled {
      color: rgba($black, 0.4);
    }

    li {
      &.btn-quicknext,
      &.btn-quickprev {
        color: $graphite;
        &:hover {
          color: $primary-alt;
        }
      }
    }
  }
  @import '~@/assets/css/mixins/_whiteInput.scss';
}
