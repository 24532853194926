.el-pagination {
  overflow-x: scroll;
  font-weight: normal;
  @include rtl-sass-value(text-align, right, left);

  .btn-prev,
  .btn-next {
    color: $primary-alt;
  }

  .el-select .el-input {
    .el-input__inner {
      @include rtl-sass-prop(padding-left, padding-right, 8px);
      @include rtl-sass-prop(padding-right, padding-left, 25px);
    }
  }
}
