
              @import "@/assets/css/au.scss";
            
.el-pagination {
  color: white;
  display: flex;
  height: 80px;
  padding: 0 !important;
  margin: 0;
  justify-content: center;
  align-items: center;
  overflow-x: hidden !important;
  width: 100%;

  &.is-background .el-pager {
    display: flex;
    li {
      background: $theme-hue;
      border: 2px $white solid;
      color: $white !important;
      justify-content: center;
      background: none !important;
      width: 30px;
      height: 30px;
      display: flex;
      padding: none;
      border-radius: 3px;
    }
  }
  .el-pagination__total {
    font-size: 12px;
    color: $white;
  }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: $white !important;
  color: $black !important;
  font-size: 12px;
}
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .btn-next {
  background: none !important;
}
.el-select {
  height: 45px !important;
  background: $black2;
  .el-input__inner {
    border: none !important;
    padding: 0 20px;
    height: 45px !important;
    color: $white;
    border-radius: 0;
    border-left: 1px solid $white;
  }
  & .el-input .el-select__caret {
    color: white !important;
    &:before {
      content: '\e6e1' !important;
    }
  }
}

.el-select__caret {
  color: white;
}
