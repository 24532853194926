@include b(form-item) {
  @include e(label) {
    color: $font-color-base;
    line-height: 1.5;
    @include rtl-sass-value(text-align, left, right);
  }
}

@include e(error) {
  @include rtl-sass-prop(left, right, 0);
}

@include when(required) {
  @include pseudo('not(.is-no-asterisk)') {
    & > .el-form-item__label:before,
    & .el-form-item__label-wrap > .el-form-item__label:before {
      @include rtl-sass-prop(margin-right, margin-left, 4px!important);
    }
  }
}
