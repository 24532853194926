@include b(date-editor) {
  .el-input__inner {
    @include rtl-sass-prop(padding-left, padding-right, 30px);
    @include rtl-sass-prop(padding-right, padding-left, 15px);
  }

  .el-range-input {
    background-color: transparent;
    color: $--input-font-color;

    @include placeholder {
      color: $--input-placeholder-color;
    }
  }

  .el-range-separator {
    font-size: $font-size-base;
    color: $--input-font-color;
    border-left: $--input-border;
    border-right: $--input-border;
    background-color: $--input-disabled-fill;
  }
}

@include b(range-editor) {
  &.el-input__inner {
    padding: 0 0.625rem;
    @include rtl-sass-prop-sd(flex-direction, row-reverse);
  }

  @include when(active) {
    border-color: $--input-border-color-hover;

    &:hover {
      border-color: $--input-border-color-hover;
    }
  }
}

@include b(picker-panel) {
  color: $graphite;
}

@include b(date-table) {
  &.today {
    span {
      color: $primary-alt;
    }
  }
}

@include b(date-picker) {
  @include e(header) {
    .el-icon-arrow-left {
      @include rtl-sass-prop-sd(transform, rotate(0deg));
    }

    .el-icon-arrow-right {
      @include rtl-sass-prop-sd(transform, rotate(0deg));
    }
  }
}
