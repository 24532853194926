.el-input__inner {
  font-weight: $font-weight-semibold;
  @include rtl-sass-prop(padding-left, padding-right, 15px);
  @include rtl-sass-prop(padding-right, padding-left, 30px);
}

.el-input__suffix {
  display: flex;
  align-items: center;
  @include rtl-sass-prop(right, left, 5px);
  @include rtl-sass-prop-sd(right, auto);
}

.el-input__prefix {
  @include rtl-sass-prop-sd(left, auto);
  @include rtl-sass-prop-sd(right, 0);
}
