.text-monospace {
  font-family: $font-family-monospace !important;
}

.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-center {
      text-align: center !important;
    }

    [dir='ltr'] {
      .text#{$infix}-start {
        text-align: left !important;
      }
      .text#{$infix}-end {
        text-align: right !important;
      }
    }

    [dir='rtl'] {
      .text#{$infix}-start {
        text-align: right !important;
      }
      .text#{$infix}-end {
        text-align: left !important;
      }
    }
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}

@each $color, $value in $theme-colors {
  @include text-variant('.text-#{$color}', $value);
}
